import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getApplication } from '../../../services/application/application';
import Select from '../../../components/forms/Select/Select';
import SimpleLoader from '../../../components/Loader/SimpleLoader';

export default function StudentApplication() {
  const { uuid } = useParams();

  const [application, setApplication] = useState(null);
  const [language, setLangugage] = useState('kk');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getApplication(uuid)
      .then((res) => {
        setApplication(res);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {};
  }, [uuid]);

  return (
    <div className='flex h-full flex-col justify-between gap-2'>
      <div className='flex flex-row justify-center'>
        <Select
          isSearchable={false}
          className='w-36'
          value={language}
          options={[
            {
              label: 'Қазақша',
              value: 'kk',
            },
            {
              label: 'Русский',
              value: 'ru',
            },
          ]}
          // placeholder={t('announcement.select_positions')}
          onChange={(e) => setLangugage(e.value)}
        />
      </div>
      {!loading ? (
        <div className='flex justify-center'>
          {language === 'kk' ? (
            <div className='border bg-white py-4 px-8 sm:h-[40rem] sm:w-[60rem]'>
              <h4 className='mt-4 text-center text-xl'>ӨТІНІШ</h4>
              <br />
              <br />
              <p>
                Менің
                <b className='underline'>
                  {' ' + application?.first_name + ' ' + application?.last_name}
                </b>
                , есімді баламды
                <b className='underline'>
                  {' ' + application?.school?.name + ' '}
                </b>
                мекемесінің {application?.school?.school_id === '092' ? 8 : 7}{' '}
                сыныбында оқу үшін конкурстық іріктеуге жіберуіңізді сұраймын.
              </p>
              <br />
              <p>
                Мен “БІЛІМ-ИННОВАЦИЯ” лицейлеріне қабылдау Ережесінің
                талаптарымен жəне оқыту тəртібімен таныстым. Үміткердің барлық
                конкурстық іріктеу кезеңдеріне қатысуын өз жауапкершілігіме
                аламын жəне міндеттенемін. Конкурстан өту кезеңдерінде
                ұйымдастыруға жəне өткізуге жауап беретін ұйымдардың
                қызметкерлерімен сыпайы түрде сөйлесуге міндеттенемін.
              </p>
              <br />
              <p>
                Ата-ананың (заңды өкілдің) аты-жөні:{' '}
                <b className='underline'>
                  {application?.parent_first_name +
                    ' ' +
                    application?.parent_last_name}
                </b>
                .
              </p>
              <br />
              <br />
              <div className='flex items-center'>
                <input
                  id='checkKZ'
                  name='checkKZ'
                  type='checkbox'
                  checked={true}
                  readOnly={true}
                  // value={isChecked}
                  // onChange={() => setIsChecked((v) => !v)}
                  className='h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500'
                />
                <label htmlFor='checkKZ' className={'ml-2 block text-red-600'}>
                  Торды белгілей отырып, осы өтінішке қол қойғанымды растаймын.
                </label>
              </div>
              {/* <Form.Group controlId="formBasicCheckbox">
    <Form.Check
      isInvalid={!checkStatement}
      checked={checkStatement}
      type="checkbox"
      onChange={() => setCheckStatement(!checkStatement)}
      label="* Отмечая эту клетку, я подтверждаю, что подписываю данное заявление."
    />
  </Form.Group> */}
              <br />
              <br />
              <br />
              <br />
              <p className='text-right'>
                {'Дата: ' + moment(application?.created).format('DD.MM.yyyy')}
              </p>
            </div>
          ) : (
            <div className='border bg-white py-4 px-8 sm:h-[40rem] sm:w-[60rem]'>
              <h4 className='mt-4 text-center text-xl'>ЗАЯВЛЕНИЕ</h4>
              <br />
              <br />
              <p>
                Прошу допустить моего ребенка,
                <b className='underline'>
                  {' ' + application?.first_name + ' ' + application?.last_name}
                </b>
                , к конкурсному отбору для обучения в{' '}
                {application?.school?.school_id}
                {application?.school?.school_id === '092' ? 8 : 7} класс
                учреждения
                <b className='underline'>
                  {' ' + application?.school.name + ' '}
                </b>
                .
              </p>
              <br />
              <p>
                Я ознакомлен(а) с требованиями Правил приема и формой обучения в
                лицеи “БІЛІМ-ИННОВАЦИЯ”. Я несу персональную ответственность за
                явку претендента на все этапы конкурсного отбора и обязуюсь
                обеспечить прохождение претендентом все этапы конкурса
                самостоятельно, без участия посредников. При прохождении
                претендентом конкурса обязуюсь быть вежливым с сотрудниками
                организаций, отвечающих за организацию и проведение этапов
                конкурсного отбора.
              </p>
              <br />
              <p>
                ФИО родителя (законного представителя):{' '}
                <b className='underline'>
                  {application?.parent_first_name +
                    ' ' +
                    application?.parent_last_name}
                </b>
                .
              </p>
              <br />
              <br />
              <div className='flex items-center'>
                <input
                  id='checkRU'
                  name='checkRU'
                  type='checkbox'
                  checked={true}
                  readOnly={true}
                  // value={isChecked}
                  // onChange={() => setIsChecked((v) => !v)}
                  className='h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500'
                />
                <label htmlFor='checkRU' className={'ml-2 block text-red-600'}>
                  Отмечая эту клетку, я подтверждаю, что подписываю данное
                  заявление.
                </label>
              </div>
              <br />
              <br />
              <br />
              <br />
              <p className='text-right'>
                {'Дата подписания: ' +
                  moment(application?.created).format('DD.MM.yyyy')}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className='flex h-12'>
          <SimpleLoader />
        </div>
      )}
    </div>
  );
}
