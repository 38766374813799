import React, { useEffect, useState } from 'react';
import { getApplicantsLite } from '../../services/application/application';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ExportApplication from '../Students/StudentInfo/ExportApplication';

export default function ExportStudentPass() {
  const [t] = useTranslation();
  const { uuid } = useParams();

  const [studentInfo, setStudentInfo] = useState(null);

  useEffect(() => {
    if (uuid) {
      getApplicantsLite(uuid).then((res) => {
        setStudentInfo(res);
      });
    }

    return () => {};
  }, [uuid]);

  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex, nofollow';
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  return (
    <div className='flex overflow-x-scroll bg-sheet p-8 lg:justify-center'>
      <ExportApplication studentInfo={studentInfo} />
    </div>
  );
}
