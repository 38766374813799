import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/forms/Buttons/Button';
import DatePicker from '../../../../components/forms/DatePicker/DatePicker';
import Select from '../../../../components/forms/Select/Select';
import { patchApplication } from '../../../../services/application/application';
import { getDistricts, getSchools } from '../../../../services/catalog/catalog';
import useSnackbar from '../../../../utils/hooks/useSnackbar';
import { getExamDates } from '../../../../services/core/coreService';
import { ExamYearContext } from '../../../../utils/contexts/ExamYearContext';
import { UserContext } from '../../../../utils/contexts/UserContext';
import {
  getAvailableClassrooms,
  getAvailableSeats,
} from '../../../../services/application/classroomService';

export default function MainInfoTab({ student, setModalOpen, refreshData }) {
  const [t] = useTranslation();
  const { addSnackbar } = useSnackbar();
  const { examYear } = useContext(ExamYearContext);
  const { user } = useContext(UserContext);

  const [examSchools, setExamSchools] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [seats, setSeats] = useState([]);
  const [examCenters, setExamCenters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [examDates, setExamDates] = useState([]);

  const [examTime, setExamTime] = useState();
  const [examCenter, setExamCenter] = useState();
  const [classroom, setClassroom] = useState();
  const [districtsLoading, setDistrictsLoading] = useState(false);
  const [classroomsLoading, setClassroomsLoading] = useState(false);
  const [seatsLoading, setSeatsLoading] = useState(false);

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    if (student?.uuid) {
      setDistrictsLoading(true);
      getDistricts()
        .then((res) => {
          let options = res.results
            .filter((dist) => dist?.is_geographical)
            .map((item) => ({
              label: item.name,
              value: item.uuid,
            }));

          if (
            student?.school?.uuid !== student?.exam_school?.uuid &&
            !options.some((o) => o.value === student?.district?.uuid)
          ) {
            options = [
              ...options,
              {
                label: student?.district?.name,
                value: student?.district?.uuid,
              },
            ];
          }

          setDistricts(options);

          const centers = res.results
            .filter((dist) => dist?.is_exam_center)
            .map((item) => ({
              label:
                item.name +
                (item?.address ? ' - ' + item.address : '') +
                (item?.is_full ? ' - Центр заполнен!' : ''),
              value: item.uuid,
            }));
          setExamCenters(centers);
        })
        .finally(() => setDistrictsLoading(false));
    }
    return () => {};
  }, [student]);

  useEffect(() => {
    if (examYear?.uuid) {
      getExamDates(examYear?.uuid).then((res) => {
        var options = res?.results?.map((date) => ({
          label: date.name,
          value: date.uuid,
        }));
        setExamDates(options);
      });

      getSchools({ page_size: 100 }).then((res) => {
        const options = res.results.map((item) => ({
          label: item?.name,
          value: item?.uuid,
          school_id: item?.school_id,
          has_social: item?.has_social,
        }));
        setExamSchools(options);
      });
    }

    return () => {
      setExamDates([]);
    };
  }, [examYear]);

  useEffect(() => {
    if (student?.uuid && districts.length) {
      setValue('parentPhoneNumber', student?.parent_phone_number);
      setValue('birthDate', new Date(student?.birth_date));
      setValue('language', student?.language);

      // if (!user?.roles?.some((r) => r?.code === 'SSA')) {
      setValue('examCenter', student?.exam_center?.uuid);
      setExamCenter(student?.exam_center?.uuid);
      setValue('district', student?.district?.uuid);
      setValue('classroom_id', student?.classroom_id?.uuid);
      setClassroom(student?.classroom_id?.uuid);
      setValue('seat_id', student?.seat_id?.uuid);
      setValue('exam', student?.exam?.uuid);
      setExamTime(student?.exam?.uuid);
      setValue('examSchool', student?.exam_school?.uuid);

      // }
      // setValue('examTime', student?.exam_time);
    }

    return () => {
      reset();
    };
  }, [student, setValue, reset, districts]);

  useEffect(() => {
    setSeats([]);

    if (examTime && examCenter) {
      setClassroomsLoading(true);
      getAvailableClassrooms({
        exam_time: examTime,
        exam_center: examCenter,
        page_size: 50,
      })
        .then((res) => {
          let rooms = [];
          if (res.count > 0) {
            rooms =
              !res.results.find(
                (r) => r.uuid === student?.classroom_id?.uuid,
              ) &&
              student?.classroom_id?.exam_time === examTime &&
              student?.classroom_id?.examCenter === examCenter
                ? [...res.results, student.classroom_id]
                : res.results;
          }
          setClassrooms(
            rooms?.sort((a, b) =>
              parseInt(a.classroom_id) > parseInt(b.classroom_id) ? 1 : -1,
            ),
          );
        })
        .finally(() => setClassroomsLoading(false));
    } else {
      setClassrooms([]);
    }
  }, [examTime, examCenter, setValue, student.classroom_id]);

  useEffect(() => {
    if (classroom) {
      setSeatsLoading(true);
      getAvailableSeats({
        classroom,
        page_size: 500,
      })
        .then((res) => {
          const seats =
            !res.results.find((s) => s.uuid === student?.seat_id?.uuid) &&
            student?.classroom_id?.uuid === classroom
              ? [...res.results, student.seat_id]
              : res.results;
          setSeats(
            seats?.sort((a, b) =>
              parseInt(a.seat_id) > parseInt(b.seat_id) ? 1 : -1,
            ),
          );
        })
        .finally(() => setSeatsLoading(false));
    } else {
      setSeats([]);
    }
  }, [classroom, setValue, student.seat_id]);

  const onSubmit = (data) => {
    setLoading(true);
    let body;
    if (
      user?.roles?.some((r) => r.code === 'TRADMIN') ||
      user?.roles?.some((r) => r.code === 'SSA')
    ) {
      body = {
        parent_phone_number: data.parentPhoneNumber,
        birth_date: format(data.birthDate, 'yyyy-MM-dd'),
        language: data.language,

        exam_school: data.examSchool,
        exam_center: '',
        classroom: '',
        seat: '',
        exam: '',
        sent: false,
      };
    } else {
      body = {
        parent_phone_number: data.parentPhoneNumber,
        birth_date: format(data.birthDate, 'yyyy-MM-dd'),
        language: data.language,

        exam_center: data.examCenter,
        district: data.district,
        classroom_id: data.classroom_id,
        seat_id: data.seat_id,
        exam: data.exam,
      };
    }

    patchApplication(student?.uuid, body)
      .then(() => {
        refreshData();
        addSnackbar(t('Успешно'), 'success');
      })
      .catch(() => {
        addSnackbar(t('Ошибка'), 'error');
      })
      .finally(() => {
        setLoading(false);
        setModalOpen(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='bg-white px-4 py-5 sm:p-6'>
        <div className='grid grid-cols-6 gap-6'>
          <div className='col-span-6 md:col-span-3'>
            <label
              htmlFor='whatsapp'
              className='block text-sm font-medium text-gray-700'
            >
              {t('signup.parent_phone')}
              <span className='text-error'>*</span>
            </label>
            <div className='mt-1 flex rounded-md shadow-sm'>
              <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500'>
                +7
              </span>
              <input
                type='text'
                name='parentPhoneNumber'
                id='parentPhoneNumber'
                className='block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm'
                // placeholder='www.example.com'
                // errorMsg={
                //   errors?.whatsapp?.type ? t('signup.parent_phone_error') : ''
                // }
                {...register('parentPhoneNumber', {
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                  pattern: {
                    value: /^7[0-9]{9}?$/,
                  },
                })}
              />
            </div>
            {errors?.whatsapp?.type && (
              <span className='mt-1 text-xs font-semibold text-error'>
                {t('signup.parent_phone_error')}
              </span>
            )}
          </div>

          <div className='col-span-6 md:col-span-3'>
            <Controller
              control={control}
              name='birthDate'
              render={({ field }) => (
                <DatePicker
                  className='w-full'
                  label={t('signup.birth_date')}
                  required
                  errorMsg={errors?.birthDate?.message}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  placeholderText={t('buttons.select')}
                  dateFormat='dd / MM / yyyy'
                  maxDate={new Date()}
                />
              )}
            />
          </div>

          <Controller
            control={control}
            name='language'
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={t('signup.study_lang')}
                required
                errorMsg={
                  errors?.language?.type ? t('signup.select_study_lang') : ''
                }
                className='col-span-6 md:col-span-3'
                value={field.value}
                options={[
                  {
                    label: 'Қазақша',
                    value: 'K',
                  },
                  {
                    label: 'Русский',
                    value: 'R',
                  },
                ]}
                // placeholder={t('announcement.select_positions')}
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />

          <Controller
            control={control}
            name='district'
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={'Район'}
                required
                isDisabled={
                  user?.school?.uuid !== student?.school?.uuid &&
                  user?.roles?.some((r) => r.code === 'VP')
                }
                errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                loading={districtsLoading}
                className='col-span-6'
                value={field.value}
                options={districts}
                // placeholder={t('announcement.select_positions')}
                onChange={(e) => {
                  // setSelectedDistrict(e);
                  field.onChange(e.value);
                }}
              />
            )}
          />

          {user?.roles?.some((r) => r.code === 'TRADMIN') ||
          user?.roles?.some((r) => r.code === 'SSA') ? (
            <Controller
              control={control}
              name='examSchool'
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  label={'Емтихан тапсырылатын мектеп'}
                  required
                  errorMsg={errors?.examSchool?.type ? 'Ошибка' : ''}
                  className='col-span-6'
                  value={field.value}
                  options={examSchools}
                  // placeholder={t('announcement.select_positions')}
                  onChange={(e) => {
                    // setSelectedDistrict(e);
                    field.onChange(e.value);
                  }}
                />
              )}
            />
          ) : (
            <React.Fragment>
              <Controller
                control={control}
                name='examCenter'
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    label={'Экзаменационный центр'}
                    required
                    isDisabled={
                      user?.school?.uuid !== student?.exam_school?.uuid
                      // user?.roles?.some((r) => r.code === 'VP')
                    }
                    errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                    className='col-span-6'
                    value={field.value}
                    options={examCenters}
                    loading={districtsLoading}
                    // placeholder={t('announcement.select_positions')}
                    onChange={(e) => {
                      setExamCenter(e.value);
                      // setSelectedDistrict(e);
                      field.onChange(e.value);
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name='exam'
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    label={'Выберите дату'}
                    required
                    errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                    className='col-span-6 sm:col-span-3'
                    value={field.value}
                    options={examDates}
                    // placeholder={t('announcement.select_positions')}
                    onChange={(e) => {
                      setValue('classroom_id', null);
                      setClassroom();
                      setExamTime(e.value);
                      field.onChange(e.value);
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name='classroom_id'
                rules={{ required: true }}
                render={({ field }) => (
                  <div className='col-span-6 flex flex-col'>
                    <Select
                      loading={classroomsLoading}
                      isDisabled={
                        !(examCenter && examTime) || classroomsLoading
                      }
                      label={'Аудитория'}
                      required
                      errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                      className=''
                      value={field.value}
                      options={classrooms.map((s) => ({
                        label: s.classroom_id,
                        value: s.uuid,
                      }))}
                      onChange={(e) => {
                        setValue('classroom_id', null);
                        setValue('seat_id', null);
                        setClassroom(e.value);
                        field.onChange(e.value);
                      }}
                    />
                    <span className='text-error'>
                      {!classroomsLoading &&
                        examCenter &&
                        examTime &&
                        !classrooms.length &&
                        'Нет свободных аудитории в это время'}
                    </span>
                  </div>
                )}
              />
              <Controller
                control={control}
                name='seat_id'
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Select
                      isDisabled={!seats?.length || seatsLoading}
                      loading={seatsLoading}
                      label={'Место'}
                      required
                      errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                      className='col-span-6'
                      value={field.value}
                      options={seats.map((s) => ({
                        label: s.seat_id,
                        value: s.uuid,
                      }))}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                    />
                  </>
                )}
              />
            </React.Fragment>
          )}
        </div>
      </div>
      <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
        <Button loading={loading} type='submit' text='Обновить' />
      </div>
    </form>
  );
}
