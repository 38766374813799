import React, { useState } from 'react';
import { Button } from '../forms/Buttons/Button';
import { getApplications } from '../../services/application/application';

export default function SearchwithResults({ selectStudent }) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className='flex flex-row gap-2'>
      <div className='relative z-20'>
        <input
          type='text'
          placeholder='Search...'
          value={query}
          onChange={handleInputChange}
          className='block w-full rounded-md border-gray-300 px-3 py-2 pr-12 shadow-sm disabled:bg-slate-200 sm:text-sm'
        />
        {showDropdown && (
          <div className='absolute top-full mt-1 w-full rounded-md bg-white shadow-md'>
            {results.map((result, index) => (
              <div
                key={index}
                className='cursor-pointer py-2 px-4 hover:bg-gray-100'
                onClick={() => {
                  selectStudent(result);
                  setShowDropdown(false);
                }}
              >
                {`${result?.first_name} ${result?.last_name}`}
              </div>
            ))}
          </div>
        )}
      </div>
      <Button
        type='button'
        text='Поиск'
        disabled={query.length < 3}
        loading={loading}
        onClick={() => {
          if (query.length >= 3) {
            setLoading(true);
            getApplications({ name: query, is_approved: true })
              .then((res) => {
                const results = res?.results?.filter((s) =>
                  ['016', '017'].includes(s?.school?.school_id),
                );
                setResults(results);
                setShowDropdown(true);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            setResults([]);
            setShowDropdown(false);
          }
        }}
      />
    </div>
  );
}
